.notification {
  box-shadow: 0.8em 0.8em 1em rgba(0, 0, 0, 0.2);
  font-size: medium;
  width: 50%;
  text-align: center;
  margin-right: 0%;
  padding-right: 0%;
}

.notification .close {
  top: 45%;
  right: 10px;
  padding: 0%;
  margin: 0%;
}

@media (max-width: 767px) {
  .notification {
    width: 100%;
  }
}
