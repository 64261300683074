.shimmer:before{
  float: left;
  display: block;
  margin-left: -5px;
  content: " ";
  background-color: lightgray;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.shimmer:after{
  float: right;
  display: block;
  margin-right: -5px;
  content: " ";
  background-color: lightgray;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.shimmer{
  padding: 0 !important;
  display: block;
  background-color: lightgray !important;
}