@media (min-width: 1405px) {
  .week-description-col {
    width: 100%;
  }
  .week-duration-col {
    width: 12%;
  }
  .week-project-col {
    width: 16%;
  }
  .week-billable-col {
    width: 5%;
  }
  .week-tags-col {
    width: 21%;
  }
  .week-action-col {
    width: 11%;
  }
}
