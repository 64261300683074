.easy-clock-tags-dropdown {
  position: static;
}

.easy-clock-tags-dropdown-menu a{
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.displaying-tags {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
