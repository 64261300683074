.logo {
  width: 50px;
  height: 50px;
}

.profile-picture {
  width: 50px;
  height: 50px;
}

.header-content p {
  margin-right: 10px;
}