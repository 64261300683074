.card .table td,
.btn-this-week,
.custom-control {
  font-size: medium;
}

.custom-control-label:hover,
.custom-control-input:hover{
  cursor: pointer;
}

.custom-control{
  padding-top: 10px;
  padding-bottom: 10px;
}

.week-filter{
  width: 280px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.easy-clock-billable-head {
  min-width: 65px;
}

@media (max-width: 940px){
  .custom-control{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .btn-this-week{
    display: none
  }
}

@media (min-width: 1405px) {
  .card .table td{
    padding-left: 1%;
    padding-right: 1%;
  }

  .card .table th{
    padding-left: 1%;
    padding-right: 0px;
  }

  .td-duration input:hover,
  input::-webkit-calendar-picker-indicator:hover{
    cursor: pointer;
  }

  .td-start-time:hover,
  .easy-clock-td-event:hover{
    cursor: default;
  }

  .easy-clock-table th,
  .easy-clock-table td {
    white-space: normal;
    overflow: visible;
  }

  .easy-clock-event-col{
    width: 35%;
  }
  .easy-clock-start-time-col{
    width: 15%;
  }
  .easy-clock-duration-col{
    width: 12%;
  }
  .easy-clock-project-col{
    width: 16%;
  }
  .easy-clock-billable-col{
    width: 5%;
  }
  .easy-clock-tags-col{
    width: 6%;
  }
  .easy-clock-action-col{
    width: 11%;
  }

  .btn:not(:last-child){
    margin: 0;
  }
}
