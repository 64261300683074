@import url(https://fonts.googleapis.com/css?family=Roboto:500);

.login {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

.google-btn {
  width: 184px;
  height: 42px;
  background-color: $google-blue;
  border-radius: 25px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  .google-icon-wrapper {
    display: flex;
    position: static;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 0s;
    border-radius: 25px;
    background-color: $white;
  }
  .google-icon {
    position: flex;
    width: 18px;
    height: 18px;
  }
  .btn-text {
    margin: auto;
    margin-left: 7px;
    color: $white;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  &:hover {
    box-shadow: 0 0 2px $google-blue;
  }
  &:active {
    background: $button-active-blue;
  }
}

.google-btn:hover{
  cursor: pointer;
}
