.load{
  height: 63px;
  animation-name: fade-in-out;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes fade-in-out {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}